import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  title: {
    id: "localization.widget.title-label",
    defaultMessage: "How to find us",
  },
  getDirections: {
    id: "localization.widget.directions-button-label",
    defaultMessage: "Get directions",
  },
});

export default messages;
