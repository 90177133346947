/** @jsx jsx */
import { responsive, Stack } from "@boxoffice/screenplay";
import { messages, Props } from "gatsby-theme-localization";
import React, { memo } from "react";
import { Icon, mdiPhone } from "shared/components/Icon";
import Link from "shared/components/Link";
import TheaterAddress from "shared/components/TheaterAddress";
import { getTinyColor } from "shared/helpers/colors";
import useIntl from "shared/helpers/i18n/useIntl";
import { jsx, Themed } from "theme-ui";

const Localization: React.FC<Props> = ({ theater }) => {
  const { formatMessage } = useIntl();

  if (!theater) {
    return null;
  }

  const lat = theater?.practicalInfo?.coordinates?.latitude || 0;
  const lng = theater?.practicalInfo?.coordinates?.longitude || 0;

  return (
    <div sx={{ display: responsive({ xs: "block", md: "flex" }) }}>
      <div
        sx={{
          flex: 2,
          marginRight: responsive({ xs: 0, md: 4 }),
          marginBottom: responsive({ xs: 3, md: 0 }),
        }}
      >
        <iframe
          src={`https://maps.google.com/maps?q=${lat},${lng}&output=embed`}
          frameBorder={0}
          allowFullScreen={false}
          aria-hidden="false"
          tabIndex={0}
          title={formatMessage(messages.title)}
          sx={{
            width: "100%",
            height: responsive({ xs: "150px", sm: "300px" }),
            border: 0,
            borderRadius: "medium",
          }}
        />
      </div>
      <div sx={{ flex: 1, paddingTop: responsive({ xs: 0, sm: 0, md: 4 }) }}>
        <Stack space={4}>
          <div>
            <Themed.h2>{formatMessage(messages.title)}</Themed.h2>
            <TheaterAddress location={theater?.practicalInfo?.location} />
          </div>
          {theater?.practicalInfo?.phone ? (
            <Link
              to={`tel:${theater.practicalInfo.phone}`}
              icon={<Icon path={mdiPhone} size={1} />}
            >
              {theater.practicalInfo.phone}
            </Link>
          ) : null}
          <div
            sx={{
              borderTopStyle: "solid",
              borderTopColor: (theme) => {
                const bg = getTinyColor(theme.rawColors?.background);
                return bg.isLight()
                  ? bg.shade(15).toHexString()
                  : bg.tint(15).toHexString();
              },
              borderTopWidth: "small",
              paddingTop: 4,
            }}
          >
            <Link
              to={`https://maps.google.com/maps/dir/Current+Location/${lat},${lng}`}
              sx={{ variant: "buttons.primary" }}
            >
              <span sx={{ display: "flex", alignItems: "center" }}>
                {formatMessage(messages.getDirections)}
                <span sx={{ fontSize: "1.5em" }}>
                  <svg
                    viewBox="0 0 24 24"
                    sx={{
                      width: "1em",
                      height: "1em",
                      display: "block",
                      marginLeft: ".25em",
                    }}
                  >
                    <path
                      fill="currentColor"
                      d="M14,14.5V12H10V15H8V11A1,1 0 0,1 9,10H14V7.5L17.5,11M21.71,11.29L12.71,2.29H12.7C12.31,1.9 11.68,1.9 11.29,2.29L2.29,11.29C1.9,11.68 1.9,12.32 2.29,12.71L11.29,21.71C11.68,22.09 12.31,22.1 12.71,21.71L21.71,12.71C22.1,12.32 22.1,11.68 21.71,11.29Z"
                    />
                  </svg>
                </span>
              </span>
            </Link>
          </div>
        </Stack>
      </div>
    </div>
  );
};

export default memo(Localization);
