/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby";
import { Localization } from "gatsby-theme-localization";
import React, { memo } from "react";
import { jsx } from "theme-ui";

import type {
  LocalizationWidgetQuery,
  PageQuery,
} from "../../../../__generated__/gatsby.types";
import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetLocalization" }>;
  data: PageQuery["theater"];
}

const LocalizationWidget: React.FC<Props> = ({
  isFirst,
  isNested,
  isLast,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
  data: theaterData,
}) => {
  const data = useStaticQuery<LocalizationWidgetQuery>(graphql`
    query LocalizationWidget {
      theater {
        practicalInfo {
          phone
          coordinates {
            latitude
            longitude
          }
          location {
            zip
            state
            country
            city
            address
          }
        }
      }
    }
  `);

  const selectedTheaterData = theaterData || data.theater;

  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <Localization theater={selectedTheaterData} />
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetLocalization on WidgetLocalization {
    id
    __typename
  }
`;

export default memo(LocalizationWidget);
